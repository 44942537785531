export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /**
   * File upload type. This type is compatible with the Apollo file upload standard
   *
   *             To manually submit a request which includes a file upload you must submit the request as a
   *             MIME multipart request with the following part names:
   *
   *             * `operations` containing a standard GQL request
   *             * `map` containing a json object which maps a part name to a GQL variable:
   *               * Object keys map to part names
   *               * Object values are arrays of GQL variables to use the part for, each variable prefixed with `variables.`
   *                 e.g. `{ "thefile": ["variables.file"] }`
   *             * Parts containing the file upload data, the name of this part is specified as a key in `map`
   *
   *             Multiple file uploads can be submitted in a single request using multipart, but is not recommended.
   */
  FileUpload: { input: any; output: any }
  /** The `JSON` type represents a string for which the contents must be a valid JSON document. */
  JSON: { input: any; output: any }
  Timestamp: { input: any; output: any }
}

export type ActivitiesFilters = {
  /** Filter to a set of IDs */
  createdAt: InputMaybe<TimestampFilter>
  /** Filter to a set of IDs */
  id: InputMaybe<IDsFilter>
  /** Filter activities by name */
  name: InputMaybe<StringFilter>
  /** Filter to a set of root IDs */
  rootId: InputMaybe<Scalars['ID']['input']>
  /** Filter to a set of target IDs */
  targetId: InputMaybe<Scalars['ID']['input']>
  /** Filter activities by object type */
  type: InputMaybe<StringFilter>
  /** Filter to a set of IDs */
  updatedAt: InputMaybe<TimestampFilter>
}

export type ActivityActor = User

export type ActivityConnection = {
  __typename?: 'ActivityConnection'
  /** Edges of the connection */
  edges: Maybe<Array<Maybe<ActivityConnectionEdge>>>
  /** Page info of the connection */
  pageInfo: Maybe<PageInfo>
  /** Total count of items in the connection. */
  totalCount: Maybe<Scalars['Int']['output']>
}

export type ActivityConnectionEdge = {
  __typename?: 'ActivityConnectionEdge'
  /** The edge cursor */
  cursor: Scalars['String']['output']
  /** Node of the Edge */
  node: Maybe<ActivityLog>
}

export type ActivityEvent = {
  __typename?: 'ActivityEvent'
  /** The event type, depending on this value the parameters of `properties` may change. */
  name: Scalars['String']['output']
  /** The payload properties of the event, encoded as a JSON string (dynamic) */
  properties: Scalars['JSON']['output']
}

export type ActivityLog = {
  __typename?: 'ActivityLog'
  actor: Maybe<ActivityActor>
  /** If the activity originated from an external application, details of the application here. */
  application: Maybe<OAuthClient>
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  /** Activity event */
  event: ActivityEvent
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  /** Organization to which this customer record belongs */
  organization: Organization
  root: Maybe<Node>
  target: Maybe<Node>
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
}

export type ActivityLogged = {
  activity: ActivityConnection
}

export type ActivityLoggedActivityArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export enum ActivitySortBy {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

/** `Address` represents a postal address. */
export type Address = {
  __typename?: 'Address'
  city: Scalars['String']['output']
  /** ISO country code */
  country: Scalars['String']['output']
  line1: Scalars['String']['output']
  line2: Maybe<Scalars['String']['output']>
  line3: Maybe<Scalars['String']['output']>
  postalCode: Scalars['String']['output']
  state: Scalars['String']['output']
}

export type AddressFilter = {
  city: InputMaybe<StringFilter>
  country: InputMaybe<StringChoiceFilter>
  line1: InputMaybe<StringFilter>
  postCode: InputMaybe<StringFilter>
  state: InputMaybe<StringFilter>
}

/** `Address` represents a postal address. */
export type AddressInput = {
  city: Scalars['String']['input']
  /** ISO country code */
  country: Scalars['String']['input']
  line1: Scalars['String']['input']
  line2: InputMaybe<Scalars['String']['input']>
  line3: InputMaybe<Scalars['String']['input']>
  postalCode: Scalars['String']['input']
  state: Scalars['String']['input']
}

export type AllocationInventory = Inventory & {
  __typename?: 'AllocationInventory'
  available: Scalars['Int']['output']
  type: InventoryKind
}

export type Booking = ActivityLogged &
  Node & {
    __typename?: 'Booking'
    activity: ActivityConnection
    /** Contract information for recurring payments */
    contract: Maybe<BookingContract>
    /** Timestamp at which this item was initially created */
    createdAt: Scalars['Timestamp']['output']
    /** Sale currency for this booking */
    currency: Scalars['String']['output']
    /** An optional reference to a customer who owns the Booking */
    customer: Maybe<Customer>
    /** A short, editable description of the booking */
    description: Maybe<Scalars['String']['output']>
    /** Unique identifier. Unique between all items including those of different types */
    id: Scalars['ID']['output']
    notes: Maybe<Array<Note>>
    organization: Organization
    /** Outstanding balance for the booking */
    outstandingBalance: Money
    passengers: Array<Passenger>
    /** ID of the payment account */
    paymentAccount: Maybe<PaymentAccount>
    /** Reservations which compose this booking */
    productReservations: Array<BookingProductReservation>
    /** A human-readable reference to the booking which is unique within an Organization */
    reference: Scalars['String']['output']
    /** Status of a booking as a whole */
    status: BookingStatus
    tags: Maybe<Array<Scalars['String']['output']>>
    /** Get the total amount paid on the booking. */
    totalPaid: Money
    /** Total price for the booking */
    totalPrice: Money
    /** Transactions made against this booking */
    transactions: Maybe<Array<Transaction>>
    /** Transactions Widget URL */
    transactionsWidgetUrl: Maybe<Scalars['String']['output']>
    /** Timestamp of the most recent modification to this item */
    updatedAt: Scalars['Timestamp']['output']
    /** Validate a discount ID against the booking */
    validateDiscount: Maybe<BookingDiscount>
    /** Validate a discount code against the booking */
    validateDiscountCode: Maybe<BookingDiscount>
  }

export type BookingActivityArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type BookingPassengersArgs = {
  direction?: InputMaybe<SortDirection>
  filters: InputMaybe<PassengerFilters>
  sortBy?: InputMaybe<PassengerSortBy>
}

export type BookingProductReservationsArgs = {
  direction: InputMaybe<SortDirection>
  filters: InputMaybe<ProductReservationFilters>
  sortBy: InputMaybe<ProductReservationSort>
}

export type BookingTotalPriceArgs = {
  onlyConfirmed?: InputMaybe<Scalars['Boolean']['input']>
}

export type BookingTransactionsArgs = {
  direction?: InputMaybe<SortDirection>
  sortBy?: InputMaybe<TransactionSortBy>
  statuses: InputMaybe<Array<PaymentStatus>>
}

export type BookingValidateDiscountArgs = {
  id: Scalars['ID']['input']
}

export type BookingValidateDiscountCodeArgs = {
  code: Scalars['String']['input']
}

/** Represents data about the contract which is used for taking contract/recurring payments. All data comes from the payment provider. */
export type BookingContract = {
  __typename?: 'BookingContract'
  /** Amount to be charged for each payment */
  amount: Scalars['Float']['output']
  /** Number of charges made */
  chargeCount: Maybe<Scalars['Int']['output']>
  /** Currency for contract payments */
  currency: Scalars['String']['output']
  /** Frequency of contract payments */
  frequency: BookingContractFrequency
  id: Scalars['ID']['output']
  /** Timestamp when the next payment will be taken */
  nextCharge: Maybe<Scalars['Timestamp']['output']>
  /** Number of payments to be taken */
  occurrences: Scalars['Int']['output']
  /** Current status of the contract */
  status: BookingContractStatus
}

export enum BookingContractFrequency {
  /** Annual payments (same date each calendar year) */
  Annually = 'ANNUALLY',
  /** Bi-monthly payments (Same datee every other calendar month) */
  Bimonthy = 'BIMONTHY',
  /** Fortnightly payments (same day of every other calendar week) */
  Fortnightly = 'FORTNIGHTLY',
  /** Monthly payments (same date each calendar month) */
  Monthly = 'MONTHLY',
  /** Weekly payments (same day of each calendar week) */
  Weekly = 'WEEKLY',
}

export enum BookingContractStatus {
  /** Contract is currently active */
  Active = 'ACTIVE',
  /** Contract is cancelled. */
  Cancelled = 'CANCELLED',
  /** The contract is in the process of being cancelled */
  Cancelling = 'CANCELLING',
  /** Contract is completed, all payments have captured */
  Completed = 'COMPLETED',
  /** Contract is currently failing -- Payments have failed to process but are being retried. */
  Failing = 'FAILING',
}

export type BookingDiscount = {
  __typename?: 'BookingDiscount'
  discount: Discount
  value: Money
}

export type BookingFilters = {
  /** Filter values by creation */
  createdAt: InputMaybe<TimestampFilter>
  /** Filter bookings by currency */
  currency: InputMaybe<StringChoiceFilter>
  /** Filter bookings by customer/contact attributes */
  customer: InputMaybe<CustomerFilters>
  /** Filter bookings by description */
  description: InputMaybe<StringFilter>
  /** Filter to a set of IDs */
  id: InputMaybe<IDsFilter>
  /** Filter bookings by passenger attributes, a booking will match if any passenger matches the given filters */
  passengers: InputMaybe<PassengerFilters>
  /** Filter bookings by their reservations */
  productReservations: InputMaybe<ProductReservationFilters>
  /** Filter bookings by reference */
  reference: InputMaybe<StringFilter>
  /** Filter bookings by their status */
  status: InputMaybe<BookingStatusFilter>
  tags: InputMaybe<TagsFilter>
  /** Filter values by last modification */
  updatedAt: InputMaybe<TimestampFilter>
}

export type BookingMutation = {
  __typename?: 'BookingMutation'
  /** Add a note */
  addNote: Note
  /**
   * ## Create a New Booking
   *
   *                     Create a new booking, the booking can be either a blank shell with no contents,
   *                     or can include some or all of the following:
   *
   *                     * Passenger Information
   *                     * Booked Products
   *                     * Transactions
   */
  create: Maybe<Booking>
  /** Set up a payment account receivable for a booking */
  createPaymentAccount: Maybe<PaymentAccount>
  /** Delete a file */
  deleteFile: Scalars['Boolean']['output']
  passengers: PassengerMutation
  /** Remove the linked customer from a booking */
  removeCustomer: Maybe<Booking>
  /** Delete a note */
  removeNote: Scalars['Boolean']['output']
  reservations: ProductReservationMutation
  transactions: TransactionMutation
  /** Update top-level booking information */
  update: Maybe<Booking>
  /** Update a note */
  updateNote: Note
  /** Upload a file */
  uploadFile: Maybe<File>
}

export type BookingMutationAddNoteArgs = {
  content: Scalars['String']['input']
  id: Scalars['ID']['input']
  title: Scalars['String']['input']
}

export type BookingMutationCreateArgs = {
  booking: CreateBookingInput
  organizationId: Scalars['ID']['input']
}

export type BookingMutationCreatePaymentAccountArgs = {
  id: Scalars['ID']['input']
}

export type BookingMutationDeleteFileArgs = {
  fileId: Scalars['ID']['input']
}

export type BookingMutationRemoveCustomerArgs = {
  id: Scalars['ID']['input']
}

export type BookingMutationRemoveNoteArgs = {
  id: Scalars['ID']['input']
}

export type BookingMutationUpdateArgs = {
  bookingId: Scalars['ID']['input']
  updates: InputMaybe<UpdateBookingInput>
}

export type BookingMutationUpdateNoteArgs = {
  content: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  title: InputMaybe<Scalars['String']['input']>
}

export type BookingMutationUploadFileArgs = {
  file: Scalars['FileUpload']['input']
  id: Scalars['ID']['input']
  title: Scalars['String']['input']
}

export type BookingProductReservation = ActivityLogged &
  Node & {
    __typename?: 'BookingProductReservation'
    activity: ActivityConnection
    /** Timestamp of when the full balance for the reservation must be paid */
    balanceDue: Scalars['Timestamp']['output']
    booking: Booking
    /** Timestamp at which this item was initially created */
    createdAt: Scalars['Timestamp']['output']
    /** Unique identifier. Unique between all items including those of different types */
    id: Scalars['ID']['output']
    notes: Maybe<Array<Note>>
    organization: Organization
    passengers: Maybe<Array<Passenger>>
    price: Maybe<Money>
    product: Product
    /** Product quantity, available only when occupancy is zero and passengers is empty */
    quantity: Scalars['Int']['output']
    reference: Maybe<Scalars['String']['output']>
    serviceEnd: Maybe<Scalars['Timestamp']['output']>
    serviceStart: Maybe<Scalars['Timestamp']['output']>
    /** The status of this product reservation */
    status: BookingStatus
    /** Optional message accompanying the status of a reservation, usually an error */
    statusMessage: Maybe<Scalars['String']['output']>
    tags: Maybe<Array<Scalars['String']['output']>>
    /** Timestamp of the most recent modification to this item */
    updatedAt: Scalars['Timestamp']['output']
  }

export type BookingProductReservationActivityArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type BookingQuery = {
  __typename?: 'BookingQuery'
  /** Get a single booking by ID */
  booking: Maybe<Booking>
  /** Search bookings */
  bookings: Maybe<Array<Booking>>
  /** Get a single booking by Organization ID and booking reference */
  byReference: Maybe<Booking>
  /** Get a single passenger by ID */
  passenger: Maybe<Passenger>
  passengers: Maybe<Array<Passenger>>
  /** Get a single product reservation by ID */
  reservation: BookingProductReservation
  /** Search product reservations */
  reservations: Maybe<Array<BookingProductReservation>>
  /** Get statistics for bookings */
  stats: BookingStats
  transaction: Transaction
  transactions: Maybe<Array<Transaction>>
}

export type BookingQueryBookingArgs = {
  id: Scalars['ID']['input']
}

export type BookingQueryBookingsArgs = {
  direction?: InputMaybe<SortDirection>
  filters: InputMaybe<BookingFilters>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  organizationId: Scalars['ID']['input']
  sortBy?: InputMaybe<BookingSort>
}

export type BookingQueryByReferenceArgs = {
  organizationId: Scalars['ID']['input']
  reference: Scalars['String']['input']
}

export type BookingQueryPassengerArgs = {
  id: Scalars['ID']['input']
}

export type BookingQueryPassengersArgs = {
  direction?: InputMaybe<SortDirection>
  filters: InputMaybe<PassengerFilters>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  organizationId: Scalars['ID']['input']
  sortBy?: InputMaybe<PassengerSortBy>
}

export type BookingQueryReservationArgs = {
  id: Scalars['ID']['input']
}

export type BookingQueryReservationsArgs = {
  direction?: InputMaybe<SortDirection>
  filters: InputMaybe<ProductReservationFilters>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  organizationId: Scalars['ID']['input']
  sortBy?: InputMaybe<ProductReservationSortBy>
}

export type BookingQueryStatsArgs = {
  filters: BookingFilters
  organizationId: Scalars['ID']['input']
}

export type BookingQueryTransactionArgs = {
  transactionId: Scalars['ID']['input']
}

export type BookingQueryTransactionsArgs = {
  direction?: InputMaybe<SortDirection>
  filters: InputMaybe<TransactionFilters>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  organizationId: Scalars['ID']['input']
  sortBy?: InputMaybe<TransactionSortBy>
}

export enum BookingSort {
  /** Sort bookings chronologically by creation */
  CreatedAt = 'CREATED_AT',
  /** Sort bookings by description */
  Description = 'DESCRIPTION',
  /** Sort by outstanding Balance */
  OutstandingBalance = 'OUTSTANDING_BALANCE',
  /** Sort bookings by reference */
  Reference = 'REFERENCE',
  /** Sort by total price */
  TotalPrice = 'TOTAL_PRICE',
  /** Sort bookings chronologically by latest modification */
  UpdatedAt = 'UPDATED_AT',
}

export type BookingStats = {
  __typename?: 'BookingStats'
  /** Count of bookings */
  count: Scalars['Int']['output']
  /** Count of passengers */
  passengers: Scalars['Int']['output']
  /** Total Value */
  totalValue: Scalars['Float']['output']
}

export enum BookingStatus {
  Archived = 'ARCHIVED',
  Cancelled = 'CANCELLED',
  Cancelling = 'CANCELLING',
  Confirmed = 'CONFIRMED',
  Error = 'ERROR',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
}

export type BookingStatusFilter = {
  exclude: InputMaybe<Array<BookingStatus>>
  include: InputMaybe<Array<BookingStatus>>
}

export type CreateBookingInput = {
  /** Sales currency */
  currency: InputMaybe<Scalars['String']['input']>
  /** Associate the booking with an existing customer */
  customerId: InputMaybe<Scalars['ID']['input']>
  /** An optional description of the booking */
  description: InputMaybe<Scalars['String']['input']>
  /** Initial notes to be added to the booking */
  notes: InputMaybe<Array<CreateNoteInput>>
  /** Passengers to create */
  passengers: InputMaybe<Array<CreatePassengerInput>>
  /** Product Reservations */
  productReservations: InputMaybe<Array<CreateProductReservationInput>>
}

export type CreateCustomerInput = {
  address: InputMaybe<AddressInput>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  knownAs: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  notes: InputMaybe<Array<InputMaybe<CreateNoteInput>>>
  organizationId: Scalars['ID']['input']
  telephone: InputMaybe<Scalars['String']['input']>
}

export type CreateDiscountInput = {
  code: Scalars['String']['input']
  criteria: InputMaybe<Scalars['JSON']['input']>
  functionUrn: InputMaybe<Scalars['String']['input']>
  isExclusive: Scalars['Boolean']['input']
  isPerPassenger: Scalars['Boolean']['input']
  isPercentage: Scalars['Boolean']['input']
  maximumUses: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  validFrom: InputMaybe<Scalars['Timestamp']['input']>
  validTo: InputMaybe<Scalars['Timestamp']['input']>
  value: Scalars['Int']['input']
}

export type CreateNoteInput = {
  content: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type CreateOAuthClientInput = {
  /** Grant Types */
  grantTypes: Array<OAuthGrantType>
  /** Application/Client Name */
  name: Scalars['String']['input']
  /** Organization ID */
  organizationId: Scalars['ID']['input']
  /** Redirect URLs */
  redirectUris: InputMaybe<Array<Scalars['String']['input']>>
}

export type CreatePassengerInput = {
  /** Associate the passenger with an existing customer */
  customerId: InputMaybe<Scalars['ID']['input']>
  /** Passenger Date of Birth */
  dateOfBirth: InputMaybe<Scalars['Timestamp']['input']>
  /** Passenger Email Address */
  email: InputMaybe<Scalars['String']['input']>
  /** Passenger first name */
  firstName: Scalars['String']['input']
  /** Passenger first name */
  lastName: Scalars['String']['input']
  /** Initial Notes to apply to the passenger */
  notes: InputMaybe<Array<CreateNoteInput>>
  /** Passenger Passport */
  passport: InputMaybe<PassengerPassportInput>
  /** Passenger Sex */
  sex: InputMaybe<Sex>
  /** Passenger Telephone */
  telephone: InputMaybe<Scalars['String']['input']>
}

export type CreateProductInput = {
  category: Scalars['String']['input']
  dates: InputMaybe<DateRangeInput>
  description: Scalars['String']['input']
  groupName: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  occupancy: InputMaybe<IntRangeInput>
  prices: InputMaybe<Array<MoneyInput>>
}

export type CreateProductReservationInput = {
  /** Auto-confirm the reservation when creating it */
  autoConfirm: InputMaybe<Scalars['Boolean']['input']>
  /** External Product ID */
  externalId: InputMaybe<Scalars['String']['input']>
  /** Indices of passengers to be assigned to the reservation */
  passengerIndices: InputMaybe<Array<Scalars['Int']['input']>>
  /** Optional price override. Defaults to product price */
  price: InputMaybe<MoneyInput>
  /** Internal Product ID to book */
  productId: InputMaybe<Scalars['ID']['input']>
  /** Product Provider ID */
  providerId: InputMaybe<Scalars['ID']['input']>
  /** Quantity of product to book. Only applicable to products which are not allocated to passengers */
  quantity: Scalars['Int']['input']
  /** Optional service end override, defaults to product dates */
  serviceEnd: InputMaybe<Scalars['Timestamp']['input']>
  /** Optional service start override, defaults to product dates */
  serviceStart: InputMaybe<Scalars['Timestamp']['input']>
}

export type CreateProviderInput = {
  credentialId: Scalars['String']['input']
  descriptorUrl: Scalars['String']['input']
  name: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
  privateCredential: Scalars['String']['input']
}

export type CreateTagPropertiesInput = {
  color: InputMaybe<Scalars['String']['input']>
  description: InputMaybe<Scalars['String']['input']>
}

export type CreateTransactionInput = {
  amount: MoneyInput
  category: InputMaybe<TransactionCategory>
  description: InputMaybe<Scalars['String']['input']>
  reference: InputMaybe<Scalars['String']['input']>
  status: PaymentStatus
}

/**
 * `Customer` represents CRM customer, it assumes no uniqueness
 *             of any set of values between different customers, except in the case of a link to an external CRM.
 */
export type Customer = ActivityLogged &
  Node & {
    __typename?: 'Customer'
    activity: ActivityConnection
    /** Postal address for the customer */
    address: Maybe<Address>
    /** Customer's Bookings */
    bookings: Maybe<Array<Booking>>
    /** Timestamp at which this item was initially created */
    createdAt: Scalars['Timestamp']['output']
    /** List of customers which are possible duplicates of this one. */
    duplicates: Maybe<Array<CustomerDuplicateCandidate>>
    /** Timestamp of when this customer was last checked for duplicates */
    duplicatesCheckedAt: Maybe<Scalars['Timestamp']['output']>
    /** E-mail address for the customer. Must be a valid e-mail address */
    email: Scalars['String']['output']
    /** First name of the customer */
    firstName: Scalars['String']['output']
    /** Unique identifier. Unique between all items including those of different types */
    id: Scalars['ID']['output']
    /** An optional name field for the customer, such as a nickname, to be used in correspondence */
    knownAs: Maybe<Scalars['String']['output']>
    /** Last name of the customer */
    lastName: Scalars['String']['output']
    /** Full name of the customer (computed) */
    name: Scalars['String']['output']
    notes: Maybe<Array<Note>>
    /** Organization to which this customer record belongs */
    organization: Organization
    tags: Maybe<Array<Scalars['String']['output']>>
    /** Primary telephone number for the customer */
    telephone: Maybe<Scalars['String']['output']>
    /** Timestamp of the most recent modification to this item */
    updatedAt: Scalars['Timestamp']['output']
  }

/**
 * `Customer` represents CRM customer, it assumes no uniqueness
 *             of any set of values between different customers, except in the case of a link to an external CRM.
 */
export type CustomerActivityArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

/**
 * `Customer` represents CRM customer, it assumes no uniqueness
 *             of any set of values between different customers, except in the case of a link to an external CRM.
 */
export type CustomerDuplicatesArgs = {
  limit?: Scalars['Int']['input']
  min_score?: Scalars['Float']['input']
}

export type CustomerDuplicateCandidate = {
  __typename?: 'CustomerDuplicateCandidate'
  customer: Customer
  score: Scalars['Float']['output']
}

export type CustomerFilters = {
  /** Filter customers by address */
  address: InputMaybe<AddressFilter>
  /** Filter values by creation */
  createdAt: InputMaybe<TimestampFilter>
  /** Filter customers by email address */
  email: InputMaybe<StringFilter>
  /** Filter customers by first name */
  firstName: InputMaybe<StringFilter>
  /** Filter to a set of IDs */
  id: InputMaybe<IDsFilter>
  /** Filter customers by last name */
  lastName: InputMaybe<StringFilter>
  /** Filter values by last modification */
  updatedAt: InputMaybe<TimestampFilter>
}

export type CustomerMutation = {
  __typename?: 'CustomerMutation'
  /** Add a note */
  addNote: Note
  /** Create a new Customer */
  create: Maybe<Customer>
  /** Delete a customer */
  delete: Maybe<Scalars['Boolean']['output']>
  /** Merge two customers */
  merge: Maybe<Customer>
  /** Delete a note */
  removeNote: Scalars['Boolean']['output']
  /** Mark a customer as having been checked for duplicates. */
  suppressDuplicates: Maybe<Customer>
  /** Update an existing Customer */
  update: Maybe<Customer>
  /** Update a note */
  updateNote: Note
}

export type CustomerMutationAddNoteArgs = {
  content: Scalars['String']['input']
  id: Scalars['ID']['input']
  title: Scalars['String']['input']
}

export type CustomerMutationCreateArgs = {
  customer: CreateCustomerInput
}

export type CustomerMutationDeleteArgs = {
  id: Scalars['ID']['input']
}

export type CustomerMutationMergeArgs = {
  source: Scalars['ID']['input']
  target: Scalars['ID']['input']
}

export type CustomerMutationRemoveNoteArgs = {
  id: Scalars['ID']['input']
}

export type CustomerMutationSuppressDuplicatesArgs = {
  id: Scalars['ID']['input']
}

export type CustomerMutationUpdateArgs = {
  id: Scalars['ID']['input']
  updates: UpdateCustomerInput
}

export type CustomerMutationUpdateNoteArgs = {
  content: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  title: InputMaybe<Scalars['String']['input']>
}

export type CustomerQuery = {
  __typename?: 'CustomerQuery'
  /** Get a customer by ID */
  customer: Maybe<Customer>
  /** List customers */
  customers: Array<Customer>
  /** Get statistics for customers */
  stats: CustomerStats
}

export type CustomerQueryCustomerArgs = {
  id: Scalars['ID']['input']
}

export type CustomerQueryCustomersArgs = {
  direction?: InputMaybe<SortDirection>
  filter: InputMaybe<CustomerFilters>
  limit: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  organizationId: Scalars['ID']['input']
  sortBy?: InputMaybe<CustomerSort>
}

export type CustomerQueryStatsArgs = {
  filters: CustomerFilters
  organizationId: Scalars['ID']['input']
}

export enum CustomerSort {
  AddressCity = 'ADDRESS_CITY',
  /** Sort customers lexically by country code */
  AddressCountry = 'ADDRESS_COUNTRY',
  AddressLine1 = 'ADDRESS_LINE1',
  AddressLine2 = 'ADDRESS_LINE2',
  AddressLine3 = 'ADDRESS_LINE3',
  /** Sort customers chronologically by creation */
  CreatedAt = 'CREATED_AT',
  /** Sort customers lexically by e-mail address */
  Email = 'EMAIL',
  /** Sort customers lexically by first name */
  FirstName = 'FIRST_NAME',
  /** Sort customers lexically by "known as" */
  KnownAs = 'KNOWN_AS',
  /** Sort customers lexically by last name */
  LastName = 'LAST_NAME',
  /** Sort customers lexically by telephone number */
  Telephone = 'TELEPHONE',
  /** Sort customers chronologically by modification */
  UpdatedAt = 'UPDATED_AT',
}

export type CustomerStats = {
  __typename?: 'CustomerStats'
  /** Count of customers */
  count: Scalars['Int']['output']
}

export type DateFilterInput = {
  end: InputMaybe<Scalars['Timestamp']['input']>
  start: InputMaybe<Scalars['Timestamp']['input']>
}

export type DateRange = {
  __typename?: 'DateRange'
  duration: Maybe<Scalars['Int']['output']>
  end: Maybe<Scalars['Timestamp']['output']>
  kind: Maybe<DateRangeKind>
  start: Maybe<Scalars['Timestamp']['output']>
}

export type DateRangeFilter = {
  end: InputMaybe<TimestampFilter>
  kind: InputMaybe<KindFilter>
  start: InputMaybe<TimestampFilter>
}

export type DateRangeInput = {
  duration: InputMaybe<Scalars['Int']['input']>
  end: InputMaybe<Scalars['Timestamp']['input']>
  kind: InputMaybe<DateRangeKind>
  start: InputMaybe<Scalars['Timestamp']['input']>
}

export enum DateRangeKind {
  /** Fixed departure date */
  Fixed = 'FIXED',
  /** Flexible dates with a fixed duration */
  FixedDuration = 'FIXED_DURATION',
  /** Flexible dates with flexible duration */
  Flexible = 'FLEXIBLE',
  /** For when no dates are provided */
  NoDate = 'NO_DATE',
}

export type Discount = {
  __typename?: 'Discount'
  code: Scalars['String']['output']
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  /** The criteria to validate this discount. May be a list of criteria or a function URN. */
  criteria: Maybe<DiscountCriteria>
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  isExclusive: Scalars['Boolean']['output']
  isPerPassenger: Scalars['Boolean']['output']
  isPercentage: Scalars['Boolean']['output']
  maximumUses: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  organization: Organization
  /** Discounts are also products, every discount is set up as a product which can be added to bookings. */
  product: Product
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
  validFrom: Maybe<Scalars['Timestamp']['output']>
  validTo: Maybe<Scalars['Timestamp']['output']>
  /** Validate if the discount can be applied to a booking as specified by `booking`. If the booking is eligible the discount and the discounted amount are returned. If the booking is not eligible null is returned */
  validate: Maybe<BookingDiscount>
  value: Scalars['Int']['output']
}

export type DiscountValidateArgs = {
  booking: CreateBookingInput
}

export type DiscountCriteria = DiscountCriteriaList | DiscountFunctionCriteria

export type DiscountCriteriaList = {
  __typename?: 'DiscountCriteriaList'
  criteria: Scalars['JSON']['output']
}

export type DiscountFilters = {
  code: InputMaybe<StringFilter>
  /** Filter values by creation */
  createdAt: InputMaybe<TimestampFilter>
  /** Filter to a set of IDs */
  id: InputMaybe<IDsFilter>
  name: InputMaybe<StringFilter>
  /** Filter values by last modification */
  updatedAt: InputMaybe<TimestampFilter>
}

export type DiscountFunctionCriteria = {
  __typename?: 'DiscountFunctionCriteria'
  functionUrn: Scalars['String']['output']
}

export enum DiscountSort {
  /** Sort discounts chronologically by creation */
  CreatedAt = 'CREATED_AT',
  /** Sort discounts by code */
  DiscountCode = 'DISCOUNT_CODE',
  /** Sort by name */
  Name = 'NAME',
  /** Sort discounts by product id */
  ProductId = 'PRODUCT_ID',
  /** Sort discounts chronologically by latest modification */
  UpdatedAt = 'UPDATED_AT',
  /** Sort by when it is valid from */
  ValidFrom = 'VALID_FROM',
  /** Sort discounts by value */
  Value = 'VALUE',
}

export type DiscountStats = {
  __typename?: 'DiscountStats'
  /** Count of discounts */
  count: Scalars['Int']['output']
}

export type DiscountsMutation = {
  __typename?: 'DiscountsMutation'
  /** Validate a discount against a booking and apply it */
  apply: Maybe<BookingProductReservation>
  create: Discount
  /** Disable a discount: Disables the discount by setting the validity period to the past. */
  disable: Maybe<Discount>
  /** Update a discount */
  update: Discount
}

export type DiscountsMutationApplyArgs = {
  bookingId: Scalars['ID']['input']
  discountId: Scalars['ID']['input']
}

export type DiscountsMutationCreateArgs = {
  discount: CreateDiscountInput
  organizationId: Scalars['ID']['input']
}

export type DiscountsMutationDisableArgs = {
  id: Scalars['ID']['input']
}

export type DiscountsMutationUpdateArgs = {
  id: Scalars['ID']['input']
  updates: UpdateDiscountInput
}

export type DiscountsQuery = {
  __typename?: 'DiscountsQuery'
  /** Get a discount by ID */
  discount: Maybe<Discount>
  /** Get a discount by organization and discount code */
  discountCode: Maybe<Discount>
  discounts: Maybe<Array<Discount>>
  /** Get statistics for discounts */
  stats: DiscountStats
}

export type DiscountsQueryDiscountArgs = {
  id: Scalars['ID']['input']
}

export type DiscountsQueryDiscountCodeArgs = {
  code: Scalars['String']['input']
  organizationId: Scalars['ID']['input']
}

export type DiscountsQueryDiscountsArgs = {
  direction?: InputMaybe<SortDirection>
  filter: InputMaybe<DiscountFilters>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  organizationId: Scalars['ID']['input']
  sortBy?: InputMaybe<DiscountSort>
}

export type DiscountsQueryStatsArgs = {
  filters: DiscountFilters
  organizationId: Scalars['ID']['input']
}

export type Extended =
  | ExtendedBoolData
  | ExtendedEmptyArrayData
  | ExtendedFloatData
  | ExtendedIntArrayData
  | ExtendedIntData
  | ExtendedStringArrayData
  | ExtendedStringData

export type ExtendedBoolData = {
  __typename?: 'ExtendedBoolData'
  name: Scalars['String']['output']
  value: Maybe<Scalars['Boolean']['output']>
}

export type ExtendedEmptyArrayData = {
  __typename?: 'ExtendedEmptyArrayData'
  name: Scalars['String']['output']
  value: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type ExtendedFloatData = {
  __typename?: 'ExtendedFloatData'
  name: Scalars['String']['output']
  value: Maybe<Scalars['Int']['output']>
}

export type ExtendedIntArrayData = {
  __typename?: 'ExtendedIntArrayData'
  name: Scalars['String']['output']
  value: Maybe<Array<Maybe<Scalars['Int']['output']>>>
}

export type ExtendedIntData = {
  __typename?: 'ExtendedIntData'
  name: Scalars['String']['output']
  value: Maybe<Scalars['Int']['output']>
}

export type ExtendedStringArrayData = {
  __typename?: 'ExtendedStringArrayData'
  name: Scalars['String']['output']
  value: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type ExtendedStringData = {
  __typename?: 'ExtendedStringData'
  name: Scalars['String']['output']
  value: Maybe<Scalars['String']['output']>
}

/**
 * File Attachment
 *             Files can be attached to supported entities. There are no limits or restrictions on file sizes or content types.
 */
export type File = {
  __typename?: 'File'
  /** File MIME type */
  contentType: Scalars['String']['output']
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  /** Original name of the uploaded file */
  filename: Scalars['String']['output']
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  /** File size (bytes) */
  size: Scalars['Int']['output']
  /** User-friendly file title */
  title: Scalars['String']['output']
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
  /** File download link. Download links are ephemeral */
  url: Scalars['String']['output']
}

export type FreeSellInventory = Inventory & {
  __typename?: 'FreeSellInventory'
  type: InventoryKind
}

export type HttpHeader = {
  __typename?: 'HttpHeader'
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type HttpMessage = {
  __typename?: 'HttpMessage'
  /** HTTP Body, truncated to 1024 bytes */
  body: Scalars['String']['output']
  /** List of HTTP Headers */
  headers: Maybe<Array<HttpHeader>>
}

/** Filter based on items which have one of the provided IDs */
export type IDsFilter = {
  ids: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type IntRange = {
  __typename?: 'IntRange'
  maximum: Scalars['Int']['output']
  minimum: Scalars['Int']['output']
}

export type IntRangeInput = {
  maximum: Scalars['Int']['input']
  minimum: Scalars['Int']['input']
}

/**
 * ## Inventory
 *             Represents the inventory for an item, multiple types of inventory exist:
 *
 *             * `FreeSellInventory`
 *             * `AllocationInventory`
 *             * `OnRequestInventory`
 *
 *             All of which respond to the `type` field
 */
export type Inventory = {
  type: InventoryKind
}

export type InventoryInput = {
  available: InputMaybe<Scalars['Int']['input']>
  errata: InputMaybe<Scalars['String']['input']>
  kind: InputMaybe<InventoryKind>
}

/** Product Inventory Kind */
export enum InventoryKind {
  /** Pre-allocated inventory. Use `available` to determine stock */
  Allocation = 'ALLOCATION',
  /** Free-sell product / unlimited inventory */
  FreeSell = 'FREE_SELL',
  /**
   * Product may be sold but out-of-band verification of inventory is required
   *                     The value of `errata` should provide details of the verification required
   */
  OnRequest = 'ON_REQUEST',
}

export type Invite = {
  __typename?: 'Invite'
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  /** User Email */
  email: Scalars['String']['output']
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
}

export type KindFilter = {
  value: InputMaybe<DateRangeKind>
}

/** Monetary value */
export type Money = {
  __typename?: 'Money'
  /** Monetary amount represented as an integer of the smallest currency denomination */
  amount: Scalars['Int']['output']
  /** Currency Code */
  currency: Scalars['String']['output']
}

export type MoneyInput = {
  amount: Scalars['Int']['input']
  currency: Scalars['String']['input']
}

export type Mutation = {
  __typename?: 'Mutation'
  booking: SingleBookingMutation
  bookings: BookingMutation
  /** Cancel an existing user invite */
  cancelInvite: Maybe<Invite>
  /** Set a new password with a token and e-mail */
  changePassword: Scalars['Boolean']['output']
  createOrganization: Maybe<Organization>
  /** Modify a specific customer */
  customer: SingleCustomerMutation
  /** Customer Mutation Operations */
  customers: CustomerMutation
  discounts: DiscountsMutation
  invite: Maybe<Invite>
  oauth: OAuthMutation
  passenger: SinglePassengerMutation
  product: SingleProductMutation
  products: ProductMutation
  /** Redeem an invite ID and register with an e-mail and password */
  register: Maybe<User>
  reservation: ReservationMutation
  /** Request a password reset */
  resetPassword: Scalars['Boolean']['output']
  tags: TagsMutation
  webhooks: WebhooksMutation
}

export type MutationBookingArgs = {
  id: Scalars['ID']['input']
}

export type MutationCancelInviteArgs = {
  id: Scalars['ID']['input']
}

export type MutationChangePasswordArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type MutationCreateOrganizationArgs = {
  name: Scalars['String']['input']
}

export type MutationCustomerArgs = {
  id: Scalars['String']['input']
}

export type MutationInviteArgs = {
  email: Scalars['String']['input']
  id: Scalars['ID']['input']
}

export type MutationPassengerArgs = {
  id: Scalars['ID']['input']
}

export type MutationProductArgs = {
  id: Scalars['String']['input']
}

export type MutationRegisterArgs = {
  email: Scalars['String']['input']
  inviteId: Scalars['ID']['input']
  name: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationReservationArgs = {
  id: Scalars['ID']['input']
}

export type MutationResetPasswordArgs = {
  email: Scalars['String']['input']
}

/** Fetches an object given its ID */
export type Node = {
  /** The ID of an object */
  id: Scalars['ID']['output']
}

/**
 * Note allows arbitrary text notes to be added to supported entities. A note is attached to exactly one entity.
 *             The note content is encrypted at-rest and is suitable for sensitive content.
 */
export type Note = ActivityLogged &
  Node & {
    __typename?: 'Note'
    activity: ActivityConnection
    /** Note body content */
    content: Scalars['String']['output']
    /** Timestamp at which this item was initially created */
    createdAt: Scalars['Timestamp']['output']
    /** Unique identifier. Unique between all items including those of different types */
    id: Scalars['ID']['output']
    /** Note title (plaintext) */
    title: Scalars['String']['output']
    /** Timestamp of the most recent modification to this item */
    updatedAt: Scalars['Timestamp']['output']
  }

/**
 * Note allows arbitrary text notes to be added to supported entities. A note is attached to exactly one entity.
 *             The note content is encrypted at-rest and is suitable for sensitive content.
 */
export type NoteActivityArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type NumericFilter = {
  maximum: InputMaybe<Scalars['Float']['input']>
  minimum: InputMaybe<Scalars['Float']['input']>
}

export type OAuthClient = {
  __typename?: 'OAuthClient'
  allowedGrantTypes: Array<OAuthGrantType>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  organization: Maybe<Organization>
  publicId: Scalars['String']['output']
  redirectUris: Maybe<Array<Scalars['String']['output']>>
  secret: Scalars['String']['output']
}

export enum OAuthGrantType {
  /** Authorization Code (Interactive user login) */
  AuthorizationCode = 'AUTHORIZATION_CODE',
  /** Refresh token, usually combined with `authorization_code` to allow tokens to be updated in the background */
  RefreshToken = 'REFRESH_TOKEN',
}

export type OAuthMutation = {
  __typename?: 'OAuthMutation'
  /** Authorize an OAuth client */
  authorize: Scalars['String']['output']
  /** Create a new OAuth Client */
  createClient: OAuthClient
}

export type OAuthMutationAuthorizeArgs = {
  clientId: Scalars['ID']['input']
  redirectUri: Scalars['String']['input']
  scopes: InputMaybe<Array<Scalars['String']['input']>>
  state: InputMaybe<Scalars['String']['input']>
}

export type OAuthMutationCreateClientArgs = {
  client: CreateOAuthClientInput
}

export type OAuthQuery = {
  __typename?: 'OAuthQuery'
  client: Maybe<OAuthClient>
}

export type OAuthQueryClientArgs = {
  clientId: Scalars['String']['input']
}

export type OnRequestInventory = Inventory & {
  __typename?: 'OnRequestInventory'
  errata: Scalars['String']['output']
  type: InventoryKind
}

/**
 * `Organization` represents a top-level business-unit.
 *             All resources are owned by an organization.
 *             While a `User` may have access to more than one `Organization`, all queries and mutations
 *             must be scoped to a single `Organization`.
 */
export type Organization = {
  __typename?: 'Organization'
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  invites: Maybe<Array<Invite>>
  /** Organization Name */
  name: Scalars['String']['output']
  settings: OrganizationSettings
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
  users: Maybe<Array<User>>
}

export type OrganizationProductSettings = {
  __typename?: 'OrganizationProductSettings'
  /** Available product categories */
  categories: Array<Scalars['String']['output']>
}

export type OrganizationSettings = {
  __typename?: 'OrganizationSettings'
  product: OrganizationProductSettings
}

export type PackageSearchInput = {
  /** Filter packages by service dates */
  dates: InputMaybe<DateFilterInput>
  /** Maximum number of results to return */
  limit: InputMaybe<Scalars['Int']['input']>
  /** (Pagination) Skip this may results */
  offset: InputMaybe<Scalars['Int']['input']>
  /** Filter packages by price */
  prices: InputMaybe<PriceFilterInput>
  /** Search only the specified providers */
  providerIds: InputMaybe<Array<Scalars['ID']['input']>>
  /** Filter packages by SKU */
  sku: InputMaybe<Scalars['String']['input']>
  /** Set the value to sort the results by */
  sort: InputMaybe<PackageSearchSort>
}

export enum PackageSearchSort {
  /** Sort by end date */
  EndDate = 'END_DATE',
  /** sort by id */
  Id = 'ID',
  /** Product Name */
  Name = 'NAME',
  /** Sort by price value */
  Price = 'PRICE',
  /** Sort by start date */
  StartDate = 'START_DATE',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>
}

export type Passenger = ActivityLogged &
  Node & {
    __typename?: 'Passenger'
    activity: ActivityConnection
    booking: Booking
    /** Timestamp of when the passenger was cancelled. If null the passenger is not cancelled */
    cancelledAt: Maybe<Scalars['Timestamp']['output']>
    /** Timestamp at which this item was initially created */
    createdAt: Scalars['Timestamp']['output']
    /** An optional reference to a CRM customer which maps to the Passenger */
    customer: Maybe<Customer>
    /** Passenger date of birth (date only) */
    dateOfBirth: Maybe<Scalars['Timestamp']['output']>
    /** An optional e-mail address for the Passenger */
    email: Maybe<Scalars['String']['output']>
    /** Files attached to the passenger */
    files: Maybe<Array<File>>
    /** Passenger first name */
    firstName: Scalars['String']['output']
    /** Unique identifier. Unique between all items including those of different types */
    id: Scalars['ID']['output']
    /** Passenger last name */
    lastName: Scalars['String']['output']
    name: Scalars['String']['output']
    /** Notes attached to the passenger */
    notes: Maybe<Array<Note>>
    organization: Organization
    /** Details of the passport being used by the passenger to travel on this booking */
    passport: Maybe<PassengerPassport>
    reservations: Maybe<Array<BookingProductReservation>>
    /** Passenger Sex */
    sex: Maybe<Sex>
    tags: Maybe<Array<Scalars['String']['output']>>
    /** Primary contact telephone for passenger */
    telephone: Maybe<Scalars['String']['output']>
    /** Timestamp of the most recent modification to this item */
    updatedAt: Scalars['Timestamp']['output']
  }

export type PassengerActivityArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type PassengerFilters = {
  /** Filter passengers by their booking */
  booking: InputMaybe<BookingFilters>
  /** Filter values by creation */
  createdAt: InputMaybe<TimestampFilter>
  /** Filter passengers by date of birth */
  dateOfBirth: InputMaybe<TimestampFilter>
  /** Filter passengers by email */
  email: InputMaybe<StringFilter>
  /** Filter passengers by first name */
  firstName: InputMaybe<StringFilter>
  /** Filter to a set of IDs */
  id: InputMaybe<IDsFilter>
  /** Filter passengers by first name */
  lastName: InputMaybe<StringFilter>
  /** Filter passengers by product reservations */
  reservations: InputMaybe<ProductReservationFilters>
  /** Filter values by last modification */
  updatedAt: InputMaybe<TimestampFilter>
}

export type PassengerMutation = {
  __typename?: 'PassengerMutation'
  /** Add a note */
  addNote: Note
  /**
   * Cancel a passenger from their booking.
   *                     Passengers cannot be cancelled if they have active reservations
   */
  cancel: Passenger
  create: Passenger
  /** Delete a file */
  deleteFile: Scalars['Boolean']['output']
  /** Delete a note */
  removeNote: Scalars['Boolean']['output']
  /**
   * Restore the passenger, clearing their cancellation status.
   *                     _Calling this mutation isn't usually needed as passengers are automatically restored when added to a reservation_.
   *                     If the passenger not cancelled this mutation has no effect.
   */
  uncancel: Passenger
  update: Passenger
  /** Update a note */
  updateNote: Note
  /** Upload a file */
  uploadFile: Maybe<File>
}

export type PassengerMutationAddNoteArgs = {
  content: Scalars['String']['input']
  id: Scalars['ID']['input']
  title: Scalars['String']['input']
}

export type PassengerMutationCancelArgs = {
  id: Scalars['ID']['input']
}

export type PassengerMutationCreateArgs = {
  bookingId: Scalars['ID']['input']
  passenger: CreatePassengerInput
}

export type PassengerMutationDeleteFileArgs = {
  fileId: Scalars['ID']['input']
}

export type PassengerMutationRemoveNoteArgs = {
  id: Scalars['ID']['input']
}

export type PassengerMutationUncancelArgs = {
  id: Scalars['ID']['input']
}

export type PassengerMutationUpdateArgs = {
  passengerId: Scalars['ID']['input']
  updates: UpdatePassengerInput
}

export type PassengerMutationUpdateNoteArgs = {
  content: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  title: InputMaybe<Scalars['String']['input']>
}

export type PassengerMutationUploadFileArgs = {
  file: Scalars['FileUpload']['input']
  id: Scalars['ID']['input']
  title: Scalars['String']['input']
}

/**
 * `Passport` represents the passport a passenger will use to travel on their booking.
 *             A passenger may have one passport per booking.
 *             All passport data is securely encrypted at-rest and thus cannot be searched.
 */
export type PassengerPassport = {
  __typename?: 'PassengerPassport'
  /** The ISO country code of the country which the passport is issued by */
  countryCode: Maybe<Scalars['String']['output']>
  /** Timestamp of when the passport expires/expired (usually a date) */
  expiresAt: Maybe<Scalars['Timestamp']['output']>
  /** Timestamp of when the passport was issued (usually a date) */
  issuedAt: Maybe<Scalars['Timestamp']['output']>
  /** Passport Number */
  number: Maybe<Scalars['String']['output']>
  /** The place in which the passport was issued. (e.g. A Consulate, National Government etc.) */
  placeOfIssue: Maybe<Scalars['String']['output']>
}

/**
 * `Passport` represents the passport a passenger will use to travel on their booking.
 *             A passenger may have one passport per booking.
 *             All passport data is securely encrypted at-rest and thus cannot be searched.
 */
export type PassengerPassportInput = {
  /** The ISO country code of the country which the passport is issued by */
  countryCode: InputMaybe<Scalars['String']['input']>
  /** Timestamp of when the passport expires/expired (usually a date) */
  expiresAt: InputMaybe<Scalars['Timestamp']['input']>
  /** Timestamp of when the passport was issued (usually a date) */
  issuedAt: InputMaybe<Scalars['Timestamp']['input']>
  /** Passport Number */
  number: InputMaybe<Scalars['String']['input']>
  /** The place in which the passport was issued. (e.g. A Consulate, National Government etc.) */
  placeOfIssue: InputMaybe<Scalars['String']['input']>
}

export enum PassengerSortBy {
  /** Sort bookings chronologically by creation */
  CreatedAt = 'CREATED_AT',
  /** Sort passengers chronologically by date of birth */
  DateOfBirth = 'DATE_OF_BIRTH',
  /** Sort passengers by email */
  Email = 'EMAIL',
  /** Sort passengers by first name */
  FirstName = 'FIRST_NAME',
  /** Sort passengers by full name */
  FullName = 'FULL_NAME',
  /** Sort passengers by last name */
  LastName = 'LAST_NAME',
  /** Sort bookings chronologically by latest modification */
  UpdatedAt = 'UPDATED_AT',
}

export type PaymentAccount = {
  __typename?: 'PaymentAccount'
  id: Scalars['ID']['output']
  lastTransactedAt: Maybe<Scalars['Timestamp']['output']>
  lastTransactionReference: Maybe<Scalars['ID']['output']>
}

export enum PaymentStatus {
  /** The payment has been authorized but not yet captured */
  Authorized = 'AUTHORIZED',
  /** Confirmed Payment */
  Confirmed = 'CONFIRMED',
  /** The payment failed to authorize or capture */
  Failed = 'FAILED',
  /** Pending Payment */
  Pending = 'PENDING',
  /** The payment was successful, but has since been refunded */
  Refunded = 'REFUNDED',
  /** Payment is void */
  Void = 'VOID',
}

export type PaymentWidgetQuery = {
  __typename?: 'PaymentWidgetQuery'
  widget: Scalars['String']['output']
}

export type PaymentWidgetQueryWidgetArgs = {
  organizationId: Scalars['ID']['input']
  widget: Scalars['String']['input']
}

export type PriceFilterInput = {
  currency: Scalars['String']['input']
  value: InputMaybe<NumericFilter>
}

export type Product = ActivityLogged &
  Node & {
    __typename?: 'Product'
    activity: ActivityConnection
    /**
     * A categorization for the product,
     *                     this may be a system-default category or a custom category for the organization
     */
    category: Scalars['String']['output']
    /** Timestamp at which this item was initially created */
    createdAt: Scalars['Timestamp']['output']
    /**
     * A pair of dates which contain the dates during which the product is fulfilled.
     *
     *                     This may be a pair of dates within which a product can be made available, or a fix pair of dates
     *                     already existent on the on the product. This value may be empty if there is no date.
     */
    dates: Maybe<DateRange>
    /** An optional long-form product description */
    description: Maybe<Scalars['String']['output']>
    /**
     * An external identifier which uniquely identifies the product within the context of the provider.
     *                     May be an empty string for internal products, but must be set for external products with a value unique
     *                     within a single provider.
     */
    externalId: Maybe<Scalars['String']['output']>
    /**
     * An optional group-name to associated related or similar products,
     *                     when empty the product does not belong to any group
     */
    groupName: Maybe<Scalars['String']['output']>
    /** Unique identifier. Unique between all items including those of different types */
    id: Scalars['ID']['output']
    /**
     * The inventory for the product.
     *                     Multiple inventory types exist, see the documentation on `Inventory` for details.
     */
    inventory: Inventory
    name: Scalars['String']['output']
    /** Integer range representing the number of passengers which can be allocated to this product */
    occupancy: Maybe<IntRange>
    organization: Organization
    /** Get the price for a product in a specific currency */
    price: Maybe<ProductPrice>
    prices: Maybe<Array<ProductPrice>>
    /** The provider from which this product is sourced, empty if the product is internal */
    provider: Maybe<ProductProvider>
    /** Reservations made of this product as part of a Booking */
    reservations: Maybe<Array<BookingProductReservation>>
    tags: Maybe<Array<Scalars['String']['output']>>
    /** Timestamp of the most recent modification to this item */
    updatedAt: Scalars['Timestamp']['output']
  }

export type ProductActivityArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
}

export type ProductPriceArgs = {
  currency?: InputMaybe<Scalars['String']['input']>
}

export type ProductFilters = {
  category: InputMaybe<StringChoiceFilter>
  /** Filter values by creation */
  createdAt: InputMaybe<TimestampFilter>
  dates: InputMaybe<DateRangeFilter>
  description: InputMaybe<StringFilter>
  groupName: InputMaybe<StringFilter>
  /** Filter to a set of IDs */
  id: InputMaybe<IDsFilter>
  name: InputMaybe<StringFilter>
  prices: InputMaybe<PriceFilterInput>
  provider: InputMaybe<ProviderFilters>
  tags: InputMaybe<TagsFilter>
  /** Filter values by last modification */
  updatedAt: InputMaybe<TimestampFilter>
}

export type ProductMutation = {
  __typename?: 'ProductMutation'
  /** Create a new product */
  create: Product
  providers: ProviderMutation
  /** Update a product */
  update: Product
}

export type ProductMutationCreateArgs = {
  organizationId: Scalars['ID']['input']
  product: CreateProductInput
}

export type ProductMutationUpdateArgs = {
  productId: Scalars['ID']['input']
  updates: UpdateProductInput
}

export type ProductPrice = {
  __typename?: 'ProductPrice'
  amount: Scalars['Int']['output']
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  currency: Scalars['String']['output']
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  product: Product
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
}

export type ProductPricing = {
  __typename?: 'ProductPricing'
  /** Balance due for the full `price`, if in the past then full price is always due */
  balanceDue: Maybe<Scalars['Timestamp']['output']>
  /** A deposit price for the product, due on booking */
  deposit: Maybe<Money>
  /** Full price for the product, due on or before `balance_due` */
  price: Money
}

export type ProductProvider = {
  __typename?: 'ProductProvider'
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  descriptorUrl: Scalars['String']['output']
  enabled: Scalars['Boolean']['output']
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  organization: Organization
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
}

export type ProductQuery = {
  __typename?: 'ProductQuery'
  /** Get a single product by provider ID and SKU */
  external: ProviderProduct
  /** List all packages available from a single provider */
  packages: Maybe<Array<ProviderProduct>>
  /** Get a single product by ID */
  product: Maybe<ProductResponse>
  /**
   * List and search known products.
   *                     Products listed are those which have been imported from an external provider ahead-of-time, or have been created
   *                     as part of a booking, both extent and extinct.
   */
  products: Maybe<Array<Product>>
  /** List external product providers */
  providers: Array<Maybe<ProductProvider>>
  search: ProductSearchQuery
  /** Get statistics for products */
  stats: ProductStats
}

export type ProductQueryExternalArgs = {
  externalId: Scalars['String']['input']
  providerId: Scalars['ID']['input']
}

export type ProductQueryPackagesArgs = {
  providerId: Scalars['ID']['input']
}

export type ProductQueryProductArgs = {
  id: Scalars['ID']['input']
}

export type ProductQueryProductsArgs = {
  direction?: InputMaybe<SortDirection>
  filters: InputMaybe<ProductFilters>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  organizationId: Scalars['ID']['input']
  sortBy?: InputMaybe<ProductSortBy>
}

export type ProductQueryProvidersArgs = {
  organizationId: Scalars['ID']['input']
}

export type ProductQueryStatsArgs = {
  filters: ProductFilters
  organizationId: Scalars['ID']['input']
}

export type ProductReservationFilters = {
  balanceDue: InputMaybe<TimestampFilter>
  booking: InputMaybe<BookingFilters>
  /** Filter values by creation */
  createdAt: InputMaybe<TimestampFilter>
  /** Filter to a set of IDs */
  id: InputMaybe<IDsFilter>
  passengers: InputMaybe<PassengerFilters>
  product: InputMaybe<ProductFilters>
  reference: InputMaybe<StringFilter>
  serviceEnd: InputMaybe<TimestampFilter>
  serviceStart: InputMaybe<TimestampFilter>
  status: InputMaybe<BookingStatusFilter>
  /** Filter values by last modification */
  updatedAt: InputMaybe<TimestampFilter>
}

export type ProductReservationMutation = {
  __typename?: 'ProductReservationMutation'
  /** Add a note */
  addNote: Note
  /** Archive a cancelled reservation. Reservations must be cancelled to be archived. */
  archive: BookingProductReservation
  /** Cancel a product reservation */
  cancel: BookingProductReservation
  /** Confirm an existing product reservation */
  confirm: BookingProductReservation
  create: BookingProductReservation
  /** Delete a note */
  removeNote: Scalars['Boolean']['output']
  /** Update an existing reservation, updates may not be made to confirmed or cancelled reservations */
  update: BookingProductReservation
  /** Update a note */
  updateNote: Note
}

export type ProductReservationMutationAddNoteArgs = {
  content: Scalars['String']['input']
  id: Scalars['ID']['input']
  title: Scalars['String']['input']
}

export type ProductReservationMutationArchiveArgs = {
  id: Scalars['ID']['input']
}

export type ProductReservationMutationCancelArgs = {
  id: Scalars['ID']['input']
}

export type ProductReservationMutationConfirmArgs = {
  id: Scalars['ID']['input']
}

export type ProductReservationMutationCreateArgs = {
  bookingId: Scalars['ID']['input']
  passengerIds?: InputMaybe<Array<Scalars['ID']['input']>>
  reservation: CreateProductReservationInput
}

export type ProductReservationMutationRemoveNoteArgs = {
  id: Scalars['ID']['input']
}

export type ProductReservationMutationUpdateArgs = {
  id: Scalars['ID']['input']
  updates: UpdateProductReservationInput
}

export type ProductReservationMutationUpdateNoteArgs = {
  content: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  title: InputMaybe<Scalars['String']['input']>
}

export enum ProductReservationSort {
  /** Sort reservations by service end */
  EndDate = 'END_DATE',
  /** Sort reservations by price amount */
  Price = 'PRICE',
  /** Sort reservations by product name */
  ProductName = 'PRODUCT_NAME',
  /** Sort reservations by service start */
  StartDate = 'START_DATE',
}

export enum ProductReservationSortBy {
  /** Sort reservations chronologically by balance due date */
  BalanceDue = 'BALANCE_DUE',
  /** Sort reservations chronologically by creation */
  CreatedAt = 'CREATED_AT',
  /** Sort reservations chronologically by service end */
  EndDate = 'END_DATE',
  /** Sort by ID */
  Id = 'ID',
  /** Sort by reservation reference */
  Reference = 'REFERENCE',
  /** Sort reservations chronologically by service start */
  StartDate = 'START_DATE',
  /** Sort reservations chronologically by modification */
  UpdatedAt = 'UPDATED_AT',
}

export type ProductResponse = Taggable & {
  __typename?: 'ProductResponse'
  /**
   * A categorization for the product,
   *                     this may be a system-default category or a custom category for the organization
   */
  category: Scalars['String']['output']
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  /**
   * A pair of dates which contain the dates during which the product is fulfilled.
   *
   *                     This may be a pair of dates within which a product can be made available, or a fix pair of dates
   *                     already existent on the on the product. This value may be empty if there is no date.
   */
  dates: Maybe<DateRange>
  /** An optional long-form product description */
  description: Maybe<Scalars['String']['output']>
  /** Error message when attempting to retrieve provided products */
  errorCode: Maybe<Scalars['String']['output']>
  /**
   * An external identifier which uniquely identifies the product within the context of the provider.
   *                     May be an empty string for internal products, but must be set for external products with a value unique
   *                     within a single provider.
   */
  externalId: Maybe<Scalars['String']['output']>
  /**
   * An optional group-name to associated related or similar products,
   *                     when empty the product does not belong to any group
   */
  groupName: Maybe<Scalars['String']['output']>
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  /**
   * The inventory for the product.
   *                     Multiple inventory types exist, see the documentation on `Inventory` for details.
   */
  inventory: Inventory
  name: Scalars['String']['output']
  /** Integer range representing the number of passengers which can be allocated to this product */
  occupancy: Maybe<IntRange>
  organization: Organization
  /** Get the price for a product in a specific currency */
  price: Maybe<ProductPrice>
  prices: Maybe<Array<ProductPrice>>
  /** The provider from which this product is sourced, empty if the product is internal */
  provider: Maybe<ProductProvider>
  /** Reservations made of this product as part of a Booking */
  reservations: Maybe<Array<BookingProductReservation>>
  tags: Maybe<Array<Scalars['String']['output']>>
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
}

export type ProductResponsePriceArgs = {
  currency?: InputMaybe<Scalars['String']['input']>
}

export type ProductSearchQuery = {
  __typename?: 'ProductSearchQuery'
  /** Search one or more providers for packages */
  packages: Maybe<Array<ProductSearchResult>>
}

export type ProductSearchQueryPackagesArgs = {
  filters: PackageSearchInput
  organizationId: Scalars['ID']['input']
}

export type ProductSearchResult = {
  __typename?: 'ProductSearchResult'
  product: ProviderProduct
  provider: ProductProvider
}

/** Possible fields on which products can be sorted */
export enum ProductSortBy {
  /** Sort lexically by product category */
  Category = 'CATEGORY',
  /** Sort products chronologically by creation */
  CreatedAt = 'CREATED_AT',
  /** Sort chronologically by end date */
  EndDate = 'END_DATE',
  /** Sort by Group Name */
  GroupName = 'GROUP_NAME',
  /** Sort by ID */
  Id = 'ID',
  /** Sort by Name */
  Name = 'NAME',
  /** Sort chronologically by start date */
  StartDate = 'START_DATE',
  /** Sort products chronologically by modification */
  UpdatedAt = 'UPDATED_AT',
}

export type ProductStats = {
  __typename?: 'ProductStats'
  /** Count of products */
  count: Scalars['Int']['output']
}

/** Product Status */
export enum ProductStatus {
  /** Product is cancelled, new sales cannot be made and excisting sales are void */
  Cancelled = 'CANCELLED',
  /** Product is on sale and may be sold */
  OnSale = 'ON_SALE',
  /** Product cannot be sold, current sales are not impacted */
  StopSell = 'STOP_SELL',
}

export type ProviderFilters = {
  /** Filter values by creation */
  createdAt: InputMaybe<TimestampFilter>
  credentialId: InputMaybe<StringFilter>
  descriptorUrl: InputMaybe<StringFilter>
  id: InputMaybe<IDsFilter>
  name: InputMaybe<StringFilter>
  /** Filter values by last modification */
  updatedAt: InputMaybe<TimestampFilter>
}

export type ProviderMutation = {
  __typename?: 'ProviderMutation'
  create: ProductProvider
  /** Create/Update package products from the specified provider into the local system */
  sync: Scalars['Int']['output']
}

export type ProviderMutationCreateArgs = {
  provider: CreateProviderInput
}

export type ProviderMutationSyncArgs = {
  id: Scalars['ID']['input']
}

/** A product as sourced from an external provider */
export type ProviderProduct = {
  __typename?: 'ProviderProduct'
  /** Optional long-form product description */
  description: Maybe<Scalars['String']['output']>
  /** Product Element */
  element: Maybe<Scalars['String']['output']>
  extended: Maybe<Array<Extended>>
  /** Product Group */
  groupName: Maybe<Scalars['String']['output']>
  /** Identity of the product within the provider, must be unique within a provider */
  id: Scalars['ID']['output']
  /** Current product Inventory */
  inventory: Maybe<Inventory>
  /** Product Name */
  name: Scalars['String']['output']
  /** Product Occupancy */
  occupancy: Maybe<IntRange>
  /** Pricing for the product */
  pricing: Maybe<Array<ProductPricing>>
  /** Related Products */
  relatedProducts: Maybe<Array<RelatedProductGroup>>
  /** (Default) Service dates for the product */
  serviceDates: Maybe<DateRange>
  /** Current product status */
  status: ProductStatus
}

export type Query = {
  __typename?: 'Query'
  activity: ActivityConnection
  /** Query Bookings and related items such as Passengers and Reservations */
  bookings: BookingQuery
  /** Query Customers */
  customers: CustomerQuery
  discounts: DiscountsQuery
  oauth: OAuthQuery
  /** Get Organization */
  organization: Organization
  /** List accessible Organizations */
  organizations: Maybe<Array<Organization>>
  payments: PaymentWidgetQuery
  /** Query Products and related items such as Providers */
  products: ProductQuery
  tags: TagsQuery
  /** Query Users (including yourself) */
  users: UserQuery
  webhooks: WebhookQuery
}

export type QueryActivityArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  direction?: InputMaybe<SortDirection>
  filters: InputMaybe<ActivitiesFilters>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
  organizationId: Scalars['ID']['input']
  sortBy: InputMaybe<ActivitySortBy>
}

export type QueryOrganizationArgs = {
  id: Scalars['ID']['input']
}

export type RelatedProductGroup = {
  __typename?: 'RelatedProductGroup'
  maxItems: Maybe<Scalars['Int']['output']>
  minItems: Maybe<Scalars['Int']['output']>
  products: Maybe<Array<Scalars['String']['output']>>
  title: Scalars['String']['output']
}

export type ReservationMutation = TaggableMutation & {
  __typename?: 'ReservationMutation'
  reservation: BookingProductReservation
  tags: TagAssociationMutation
}

export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE',
}

export type SingleBookingMutation = TaggableMutation & {
  __typename?: 'SingleBookingMutation'
  booking: Booking
  tags: TagAssociationMutation
}

export type SingleCustomerMutation = {
  __typename?: 'SingleCustomerMutation'
  customer: Customer
  tags: TagAssociationMutation
}

export type SinglePassengerMutation = TaggableMutation & {
  __typename?: 'SinglePassengerMutation'
  passenger: Passenger
  tags: TagAssociationMutation
}

export type SingleProductMutation = TaggableMutation & {
  __typename?: 'SingleProductMutation'
  tags: TagAssociationMutation
}

export enum SortDirection {
  /** Sort in ascending order: lexically A-Z, chronologically oldest-first, numerically lowest-first */
  Asc = 'ASC',
  /** Sort in descending order: lexically Z-A, chronologically newest-first, numerically highest-first */
  Desc = 'DESC',
}

export type StringChoiceFilter = {
  operation: StringChoiceFilterOperation
  values: InputMaybe<Array<Scalars['String']['input']>>
}

export enum StringChoiceFilterOperation {
  /** Exclude the specified values */
  Exclude = 'EXCLUDE',
  /** Include the specified values */
  Include = 'INCLUDE',
}

/**
 * Filter a string field by a given value and operation.
 *             All filter operations are case-insensitive.
 */
export type StringFilter = {
  operation: InputMaybe<StringFilterOperation>
  value: InputMaybe<Scalars['String']['input']>
}

/** Filter criteria for a `String` field. All operations are case-insensitive */
export enum StringFilterOperation {
  /** Values containing the given value */
  Contains = 'CONTAINS',
  /** Values ending with the given value */
  EndsWith = 'ENDS_WITH',
  /** Values match */
  Equals = 'EQUALS',
  /** Values starting with the given value */
  StartsWith = 'STARTS_WITH',
}

export type Subscription = {
  __typename?: 'Subscription'
  id: Maybe<Scalars['ID']['output']>
}

export type Tag = {
  __typename?: 'Tag'
  color: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Timestamp']['output']
  description: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  organization: Organization
  scope: TagScope
  tag: Scalars['String']['output']
  updatedAt: Scalars['Timestamp']['output']
}

export type TagAssociationMutation = {
  __typename?: 'TagAssociationMutation'
  update: Maybe<Array<Scalars['String']['output']>>
}

export type TagAssociationMutationUpdateArgs = {
  operation: TagOperation
  tags: InputMaybe<Array<Scalars['String']['input']>>
}

export enum TagMatchType {
  All = 'ALL',
  Any = 'ANY',
}

export enum TagOperation {
  Add = 'ADD',
  Remove = 'REMOVE',
  Replace = 'REPLACE',
}

export type TagPropertyUpdates = {
  color: InputMaybe<Scalars['String']['input']>
  description: InputMaybe<Scalars['String']['input']>
}

export enum TagScope {
  Bookings = 'BOOKINGS',
  Customers = 'CUSTOMERS',
  Passengers = 'PASSENGERS',
  Products = 'PRODUCTS',
  ProductReservations = 'PRODUCT_RESERVATIONS',
}

export type Taggable = {
  tags: Maybe<Array<Scalars['String']['output']>>
}

export type TaggableMutation = {
  tags: TagAssociationMutation
}

export type TagsConnection = {
  __typename?: 'TagsConnection'
  /** Edges of the connection */
  edges: Maybe<Array<Maybe<TagsConnectionEdge>>>
  /** Page info of the connection */
  pageInfo: Maybe<PageInfo>
  /** Total count of items in the connection. */
  totalCount: Maybe<Scalars['Int']['output']>
}

export type TagsConnectionEdge = {
  __typename?: 'TagsConnectionEdge'
  /** The edge cursor */
  cursor: Scalars['String']['output']
  /** Node of the Edge */
  node: Maybe<Tag>
}

export type TagsFilter = {
  exclude: InputMaybe<Array<Scalars['String']['input']>>
  include: InputMaybe<Array<Scalars['String']['input']>>
  match: InputMaybe<TagMatchType>
}

export type TagsMutation = {
  __typename?: 'TagsMutation'
  create: Tag
  update: Tag
}

export type TagsMutationCreateArgs = {
  organizationId: Scalars['String']['input']
  properties: CreateTagPropertiesInput
  scope: TagScope
  tag: Scalars['String']['input']
}

export type TagsMutationUpdateArgs = {
  organizationId: Scalars['ID']['input']
  scope: TagScope
  tag: Scalars['String']['input']
  updates: TagPropertyUpdates
}

export type TagsQuery = {
  __typename?: 'TagsQuery'
  find: Maybe<Array<Tag>>
  list: TagsConnection
  suggest: Maybe<Array<Scalars['String']['output']>>
}

export type TagsQueryFindArgs = {
  organizationId: Scalars['ID']['input']
  scope: TagScope
  tags: Array<Scalars['String']['input']>
}

export type TagsQueryListArgs = {
  after: InputMaybe<Scalars['String']['input']>
  before: InputMaybe<Scalars['String']['input']>
  first: InputMaybe<Scalars['Int']['input']>
  last: InputMaybe<Scalars['Int']['input']>
  organizationId: Scalars['ID']['input']
  prefix: InputMaybe<Scalars['String']['input']>
  scope: InputMaybe<TagScope>
}

export type TagsQuerySuggestArgs = {
  organizationId: Scalars['ID']['input']
  prefix: Scalars['String']['input']
  scope: TagScope
}

export type TimestampFilter = {
  /** Earliest value to return */
  since: InputMaybe<Scalars['Timestamp']['input']>
  /** Latest value to return */
  until: InputMaybe<Scalars['Timestamp']['input']>
}

export type Transaction = {
  __typename?: 'Transaction'
  amount: Maybe<Money>
  booking: Booking
  category: TransactionCategory
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  /** An optional description relating to this transaction */
  description: Maybe<Scalars['String']['output']>
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  organization: Organization
  reference: Maybe<Scalars['String']['output']>
  status: PaymentStatus
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
}

export enum TransactionCategory {
  BankTransfer = 'BANK_TRANSFER',
  Card = 'CARD',
  Cash = 'CASH',
  InternalTransfer = 'INTERNAL_TRANSFER',
}

export type TransactionFilters = {
  amountCurrency: InputMaybe<StringChoiceFilter>
  amountValue: InputMaybe<NumericFilter>
  booking: InputMaybe<BookingFilters>
  /** Filter values by creation */
  createdAt: InputMaybe<TimestampFilter>
  /** Filter to a set of IDs */
  id: InputMaybe<IDsFilter>
  reference: InputMaybe<StringFilter>
  /** Filter values by last modification */
  updatedAt: InputMaybe<TimestampFilter>
}

export type TransactionMutation = {
  __typename?: 'TransactionMutation'
  /** Capture the partial or full amount for an authorized transaction */
  capture: Transaction
  create: Transaction
}

export type TransactionMutationCaptureArgs = {
  id: Scalars['ID']['input']
}

export type TransactionMutationCreateArgs = {
  bookingId: Scalars['ID']['input']
  transaction: CreateTransactionInput
}

export enum TransactionSortBy {
  Amount = 'AMOUNT',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  Status = 'STATUS',
  UpdatedAt = 'UPDATED_AT',
}

export type UpdateBookingInput = {
  /** Change the active contract Id */
  contractId: InputMaybe<Scalars['String']['input']>
  /** Change the customer ID */
  customerId: InputMaybe<Scalars['ID']['input']>
  /** Change the description of the booking */
  description: InputMaybe<Scalars['String']['input']>
  /** Change the status of the booking */
  status: InputMaybe<BookingStatus>
}

export type UpdateCustomerInput = {
  address: InputMaybe<AddressInput>
  email: InputMaybe<Scalars['String']['input']>
  firstName: InputMaybe<Scalars['String']['input']>
  knownAs: InputMaybe<Scalars['String']['input']>
  lastName: InputMaybe<Scalars['String']['input']>
  telephone: InputMaybe<Scalars['String']['input']>
}

export type UpdateDiscountInput = {
  code: InputMaybe<Scalars['String']['input']>
  criteria: InputMaybe<Scalars['JSON']['input']>
  functionUrn: InputMaybe<Scalars['String']['input']>
  isExclusive: InputMaybe<Scalars['Boolean']['input']>
  isPerPassenger: InputMaybe<Scalars['Boolean']['input']>
  isPercentage: InputMaybe<Scalars['Boolean']['input']>
  maximumUses: InputMaybe<Scalars['Int']['input']>
  name: InputMaybe<Scalars['String']['input']>
  validFrom: InputMaybe<Scalars['Timestamp']['input']>
  validTo: InputMaybe<Scalars['Timestamp']['input']>
  value: InputMaybe<Scalars['Int']['input']>
}

export type UpdatePassengerInput = {
  /** Set to `true` to clear the date of brith */
  clearDateOfBirth: InputMaybe<Scalars['Boolean']['input']>
  /** New Customer ID */
  customerId: InputMaybe<Scalars['ID']['input']>
  /** New Customer Date of Birth */
  dateOfBirth: InputMaybe<Scalars['Timestamp']['input']>
  /** New Passenger Email */
  email: InputMaybe<Scalars['String']['input']>
  /** New Passenger First Name */
  firstName: InputMaybe<Scalars['String']['input']>
  /** New Passenger Last Name */
  lastName: InputMaybe<Scalars['String']['input']>
  passport: InputMaybe<PassengerPassportInput>
  sex: InputMaybe<Sex>
  /** Passenger Telephone */
  telephone: InputMaybe<Scalars['String']['input']>
}

export type UpdateProductInput = {
  category: InputMaybe<Scalars['String']['input']>
  dates: InputMaybe<DateRangeInput>
  description: InputMaybe<Scalars['String']['input']>
  groupName: InputMaybe<Scalars['String']['input']>
  inventory: InputMaybe<InventoryInput>
  name: InputMaybe<Scalars['String']['input']>
  occupancy: InputMaybe<IntRangeInput>
  prices: InputMaybe<Array<InputMaybe<MoneyInput>>>
}

export type UpdateProductReservationInput = {
  passengers: InputMaybe<Array<Scalars['ID']['input']>>
  price: InputMaybe<MoneyInput>
  serviceEnd: InputMaybe<Scalars['Timestamp']['input']>
  serviceStart: InputMaybe<Scalars['Timestamp']['input']>
}

export type User = {
  __typename?: 'User'
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  email: Scalars['String']['output']
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  organization: Organization
  roles: Array<Scalars['String']['output']>
  signIns: Maybe<Array<UserSignIn>>
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
}

export type UserSignInsArgs = {
  isSuccessful: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type UserQuery = {
  __typename?: 'UserQuery'
  /** Gets the identity of the user making the API call */
  me: User
  user: Maybe<User>
}

export type UserQueryUserArgs = {
  email: InputMaybe<Scalars['String']['input']>
  id: InputMaybe<Scalars['ID']['input']>
}

/** `UserSignIn` represents an where a login was made or attempted using a known e-mail address. */
export type UserSignIn = {
  __typename?: 'UserSignIn'
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  /** Indicates if the sign-in was successful */
  isSuccessful: Scalars['Boolean']['output']
  /** The Remote IP address of the user connection */
  remoteAddr: Scalars['String']['output']
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
  user: User
  /** The HTTP User-Agent header value */
  userAgent: Scalars['String']['output']
}

/** Contains details of an individual webhook call */
export type WebhookCall = {
  __typename?: 'WebhookCall'
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  /** The event which was triggerred */
  event: WebhookEvent
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  /** Time taken to complete the Webook call, in milliseconds */
  millis: Scalars['Float']['output']
  /** Description of the HTTP request which was made */
  request: HttpMessage
  /** Description of the HTTP response which was received */
  response: Maybe<HttpMessage>
  /** The HTTP Response Code returned by the Webhook call */
  responseCode: Scalars['Int']['output']
  /** The subscription which the call was made to */
  subscription: WebhookSubscription
  /** The timestamp of the HTTP request */
  timestamp: Scalars['Timestamp']['output']
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
}

/**
 * A list of events which webhooks can subscribe to, including pseudo-events which match multiple event types,
 *             plus the `ANY` event which matches all events.
 */
export enum WebhookEvent {
  /**
   * **Trigger**: *all* events, including new event types added after subscription creation
   *                     **Code**: _variable_
   *                     **Payload**: _variable_
   */
  Any = 'ANY',
  /**
   * **Trigger**: all booking events
   *                     **Code**: _variable_
   *                     **Payload**: `Booking`
   */
  BookingAny = 'BOOKING_ANY',
  /**
   * **Trigger**: when a new booking is created.
   *                     **Code**: booking.created
   *                     **Payload**: `Booking`
   */
  BookingCreated = 'BOOKING_CREATED',
  /**
   * **Trigger**: when a booking is updated
   *                     **Code**: booking.updated
   *                     **Payload**: `Booking`
   */
  BookingUpdated = 'BOOKING_UPDATED',
  /**
   * **Trigger**: Any contract event
   *                     **Code**: _variable_
   *                     **Payload**: `{ booking => Booking, contract => Contract }`
   */
  ContractAny = 'CONTRACT_ANY',
  /**
   * **Trigger**: Contract Finish -- Either all payments taken or terminated early.
   *                     **Code**: booking.contract.finished
   *                     **Payload**: `{ booking => Booking, contract => Contract }`
   */
  ContractFinished = 'CONTRACT_FINISHED',
  /**
   * **Trigger**: Contract Commencement (first payment)
   *                     **Code**: booking.contract.start
   *                     **Payload**: `{ booking => Booking, contract => Contract }`
   */
  ContractStarted = 'CONTRACT_STARTED',
  /**
   * **Trigger**: Contract Update -- Including Payment against a contract
   *                     **Code**: booking.contract.update
   *                     **Payload**: `{ booking => Booking, contract => Contract }`
   */
  ContractUpdated = 'CONTRACT_UPDATED',
  CustomerAny = 'CUSTOMER_ANY',
  CustomerCreated = 'CUSTOMER_CREATED',
  CustomerDeleted = 'CUSTOMER_DELETED',
  CustomerUpdated = 'CUSTOMER_UPDATED',
  /**
   * **Trigger**: on note creation
   *                     **Code**: note.created
   *                     **Payload**: `Note` + Attached Object
   */
  NoteCreated = 'NOTE_CREATED',
  /**
   * **Trigger**: on passenger creation
   *                     **Code**: booking.passenger.created
   *                     **Payload**: `Passenger`
   */
  PassengerCreated = 'PASSENGER_CREATED',
  /**
   * **Trigger**: on passenger update
   *                     **Code**: booking.passenger.updated
   *                     **Payload**: `Passenger`
   */
  PassengerUpdated = 'PASSENGER_UPDATED',
  /**
   * **Trigger**: on any reservation event
   *                     **Code**: _variable_
   *                     **Payload**: `ProductReservation`
   */
  ReservationAny = 'RESERVATION_ANY',
  /**
   * **Trigger**: when a cancelled reservation is moved to the archive
   *                     **Code**: booking.reservation.archived
   *                     **Payload**: `ProductReservation`
   */
  ReservationArchived = 'RESERVATION_ARCHIVED',
  /**
   * **Trigger**: when a reservation has been cancelled
   *                     **Code**: booking.reservation.cancelled
   *                     **Payload**: `ProductReservation`
   */
  ReservationCancelled = 'RESERVATION_CANCELLED',
  /**
   * **Trigger**: when a reservation has been confirmed.
   *                     **Code**: booking.reservation.confirmed
   *                     **Payload**: `ProductReservation`
   */
  ReservationConfirmed = 'RESERVATION_CONFIRMED',
  /**
   * **Trigger**: when a new reservation is created.
   *                     **Code**: booking.reservation.created
   *                     **Payload**: `ProductReservation`
   */
  ReservationCreated = 'RESERVATION_CREATED',
  /**
   * **Trigger**: when a reservation can't be moved from one status to another
   *                     **Code**: booking.reservation.errored
   *                     **Payload**: `ProductReservation`
   */
  ReservationErrored = 'RESERVATION_ERRORED',
  /**
   * **Trigger**: when an existing reservation is updated.
   *                     **Code**: booking.reservation.updated
   *                     **Payload**: `ProductReservation`
   */
  ReservationUpdated = 'RESERVATION_UPDATED',
}

export type WebhookQuery = {
  __typename?: 'WebhookQuery'
  calls: Maybe<Array<WebhookCall>>
}

export type WebhookQueryCallsArgs = {
  id: Scalars['ID']['input']
}

export type WebhookSubscription = {
  __typename?: 'WebhookSubscription'
  calls: Maybe<Array<WebhookCall>>
  /** Timestamp at which this item was initially created */
  createdAt: Scalars['Timestamp']['output']
  events: Array<WebhookEvent>
  /** Unique identifier. Unique between all items including those of different types */
  id: Scalars['ID']['output']
  organization: Organization
  /** Timestamp of the most recent modification to this item */
  updatedAt: Scalars['Timestamp']['output']
  url: Scalars['String']['output']
}

export type WebhooksMutation = {
  __typename?: 'WebhooksMutation'
  createSubscription: WebhookSubscription
  deleteSubscription: Scalars['Boolean']['output']
}

export type WebhooksMutationCreateSubscriptionArgs = {
  events: Array<WebhookEvent>
  organizationId: Scalars['ID']['input']
  url: Scalars['String']['input']
}

export type WebhooksMutationDeleteSubscriptionArgs = {
  id: Scalars['ID']['input']
}
